import { Link } from "gatsby"
import React from "react"

export const Panel = ({ data }) => {
  return (
    <div className="panel">
      <h2>{data.primary.paneltitle.text}</h2>
      <div className="body">
        {(data.primary.panelcontent && data.primary.panelcontent.text) || ""}
        {(data.items || []).map(({ item }) => (
          <Link
            key={item.uid}
            to={"/rugaciune/" + item.uid}
            className="card arrow"
          >
            <h3>{item.document.data.title.text}</h3>
            <p>{item.document.data.content.text.substr(0, 80)} [...]</p>
          </Link>
        ))}
      </div>
    </div>
  )
}
