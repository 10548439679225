import React from "react"
import Layout from "../components/layout"
import { Panel } from "../components/panel"

const Homepage = props => {
  const data = props.pageContext.data
  return (
    <Layout title={data.title.text} description={data.description.text}>
      {data.body.map((item, i) => (
        <Panel key={i} data={item} />
      ))}
    </Layout>
  )
}
export default Homepage
